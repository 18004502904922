body {
  margin: 0;
  font-family: "Roboto", "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: black;
}

#top-page {
  background-color: #f7f7f7;
  height: 1000px;
}

#logo {
  margin-top: 100px;
  margin-bottom: 100px;
}

.manufacturers {
  display: flex;
  margin-top: 100px;
  margin-bottom: 60px;
}

.eletronic-section {
  display: flex;
  justify-content: center;
}

#about-us {
  width: 310px;
  margin: 30px 90px;
  text-align: left;
}

.about-us-section {
  display: flex;
  height: 500px;
}

.link {
  margin-top: 70px;
  opacity: 0.3;
}

.main-services {
  width: 230px;
  border-radius: 10px;
  color: white;
  background-color: red;
  margin: 0px 50px;
}

.main-services > a > img {
  width: 230px;
  border-radius: 10;
}

.about-us-title {
  font-family: "Lato";
  font-size: 32px;
  font-weight: 900;
  color: #8e8e8e;
  margin-bottom: 20px;
}

.about-us-body {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 300;
  color: black;
  line-height: 30px;
}

.workshop {
  width: 800px;
  height: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  margin-top: -100px;
  position: absolute;
  right: 0;
}

.workshop > img {
  width: 800px;
  vertical-align: top;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 60px 60px 0 0;
  border-color: #f4f4f4 white transparent transparent;
  display: inline-block;
  flex: 0.1;
  margin-top: 30px;
}

.map-visual {
  height: 400px;
  flex: 1;
  text-align: justify;
}

.map-section {
  background-color: #f2f2f2;
  display: flex;
  background-color: #f2f2f2;
  margin: 60px 30px;
}

.map-address {
  flex: 1;
  height: 40%;
  font-size: 20px;
  font-weight: 700;
  color: #777777;
  line-height: 35px;
  padding-top: 10px;
}

.header-line {
  border-top: 1px solid #f4f4f4;
  margin-top: 30px;
}

.header {
  text-align: left;
  color: #8e8e8e;
  margin: 0px 20px;
  display: flex;
}

.header-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 32px;
  margin-right: 20px;
}

.header-subtitle {
  font-family: "Lato";
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
}

.header-text-body {
  background-color: #f4f4f4;
  padding: 10px 10px;
  margin-top: 30px;
}

.vertical-section {
  width: 290px;
  background-color: #f8f8f8;
  margin: 20px;
  margin-bottom: 140px;
}

.vertical-section li {
  line-height: 50px;
  text-align: left;
  padding-left: 30px;
}

ul {
  list-style-image: url("/svg/item.svg");
}

.services {
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.services a:link,
.services a:hover,
.services a:active,
.services a:visited {
  text-decoration: none;
  color: white;
}

a:link,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: black;
}

.button_phone {
  padding-right: 20px;
  float: right;
  padding-top: 10px;
}

.button_text {
  display: inline-block;
  margin-top: 11px;
  font-weight: 700;
  font-size: 14px;
}

.contact_button {
  position: fixed;
  height: 38px;
  width: 190px;
  border-radius: 20px;
  background-color: #cbcbcb;
  font-size: 14px;
  font-family: Roboto;
  text-transform: uppercase;
  right: 10px;
  top: 10px;
  z-index: 9999;
}

.contact_button_image {
  float: left;
  width: 38px;
  border-radius: 20px;
}

.section {
  border-bottom: 2px solid #e84234;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 60px;
  margin-bottom: 80px;
}

.section_text {
  font-family: Roboto;
  font-size: 22px;
  color: #6a6a6a;
  font-weight: 300;
  vertical-align: super;
}

.maintanence {
  text-align: left;
  margin: 0px 190px;
  font-weight: 300;
  line-height: 30px;
}

.maintanence p {
  margin: 15px 20px;
}

.maintanence li {
  margin-bottom: 60px;
}

.panel_red {
  background-color: #e84234;
  color: #ebebeb;
}

.panel_red ul {
  list-style-image: url("/svg/item_neg.svg");
}

.panel_gray {
  background-color: #f8f8f8;
  color: #606060;
}

.panel {
  width: 390px;
  padding: 40px;
  margin: 20px;
  text-align: left;
  line-height: 26px;
}

.panel p {
  margin-left: 10px;
}

.mechanic-section {
  display: flex;
  justify-content: center;
}

.contact-section {
  display: flex;
  padding: 30px;
}

.contact-item {
  padding: 15px;
}

.contact-item span {
  width: 50px;
  display: inline-block;
  vertical-align: top;
}

.vertical-section img {
  width: 290px;
}

.map-schedule-time {
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  text-align: left;
  line-height: 30px;
  width: 60%;
  margin: auto;
  padding-top: 20px;
}

.map-schedule-days {
  font-size: 14px;
  font-family: "Lato";
  font-weight: 300;
  text-align: left;
  line-height: 30px;
  width: 60%;
  margin: auto;
  padding-top: 20px;
}

@media (max-width: 650px) {
  #logo {
    width: 80%;
  }

  #top-page {
    height: inherit;
  }

  .services {
    flex-direction: column;
  }

  .workshop {
    width: 100%;
    height: inherit;
    margin-top: 0;
    position: inherit;
  }

  .workshop > img {
    width: 100%;
  }

  div > .link {
    display: none;
  }

  .manufacturers {
    flex-direction: column;
  }

  .maintanence {
    margin: 0px 20px;
  }

  .mechanic-section {
    flex-direction: column;
  }

  .panel {
    width: inherit;
  }

  .vertical-section {
    width: inherit;
    margin-bottom: inherit;
  }

  .vertical-section img {
    width: 100%;
  }

  .header-subtitle {
    display: none;
  }

  .header {
    margin: 0;
  }

  .eletronic-section {
    flex-direction: column;
  }

  .contact-section {
    flex-direction: column;
  }

  .map-section {
    margin: 20px 0 0 0;
    display: inherit;
  }

  .map-visual {
    display: inherit;
  }

  .map-schedule-time {
    width: 100%;
  }

  .map-schedule-days {
    width: 80%;
  }

  .main-services {
    width: inherit;
    margin-bottom: 20px;
  }

  .main-services:last-child {
    margin-bottom: 100px;
  }

  .main-services > a > img {
    width: 100%;
    border-radius: 10px;
  }

  .about-us-section {
    height: inherit;
    flex-direction: column-reverse;
  }

  #about-us {
    width: inherit;
    margin: 30px;
  }
}
